import { createContext, useContext, useState, useCallback } from "react";
import { YouTubePlayer } from "react-youtube";

type VideoPlayerContextType = {
	player: YouTubePlayer | null;
	setPlayer: (player: YouTubePlayer | null) => void;
	isPlayerVisible: boolean;
	setIsPlayerVisible: (visible: boolean) => void;
	currentVideo: {
		videoId: string;
		title: string;
		timestamp?: number;
	} | null;
	setCurrentVideo: (
		video: {
			videoId: string;
			title: string;
			timestamp?: number;
		} | null,
	) => void;
};

const VideoPlayerContext = createContext<VideoPlayerContextType | null>(null);

export function VideoPlayerProvider({
	children,
}: { children: React.ReactNode }) {
	const [player, setPlayer] = useState<YouTubePlayer | null>(null);
	const [isPlayerVisible, setIsPlayerVisible] = useState(false);
	const [currentVideo, setCurrentVideo] = useState<{
		videoId: string;
		title: string;
		timestamp?: number;
	} | null>(null);

	return (
		<VideoPlayerContext.Provider
			value={{
				player,
				setPlayer,
				isPlayerVisible,
				setIsPlayerVisible,
				currentVideo,
				setCurrentVideo,
			}}
		>
			{children}
		</VideoPlayerContext.Provider>
	);
}

export function useVideoPlayer() {
	const context = useContext(VideoPlayerContext);
	if (!context) {
		throw new Error("useVideoPlayer must be used within a VideoPlayerProvider");
	}
	return context;
}
